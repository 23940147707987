html {
  font-family: "Poppins", sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spacer {
  padding-top: 10px;
}

.ag-header-row.ag-header-row-column {
  color: white !important;
}

.page-wrapper {
  height: 100%;
}

.page-wrapper > .makeStyles-root-1,
.page-wrapper > .makeStyles-root-1 > .makeStyles-content-2 {
  height: 100%;
  background-color: white !important;
}

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-header-cell,
.ag-cell,
.ag-header-cell .ag-header-cell-sortable,
.ag-header-cell .ag-floating-filter,
.ag-header-group-cell,
.ag-header-group-cell-with-group {
  text-align: left;
  display: block;
  height: 60px;
}

.ag-theme-alpine {
  color: #badbfe;
}

.ag-theme-alpine .ag-row {
  font-size: 12px !important;
}

.ag-theme-alpine .ag-header {
  background-color: #e7edf3 !important;
}

/* .ag-theme-alpine .ag-row-odd {
  background-color: #ffffff;
} */

/* .ag-header-row,
.ag-row,
.ag-header-row-column-filter {
} */

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  text-align: left;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  content: " \2714";
}

.ag-cell-value {
  text-overflow: "ellipsis" !important;
  white-space: "nowrap" !important;
  display: block;
}

.content-spacer {
  padding-top: 10px !important;
}

.map-container {
  height: 100vh;
  width: 100%;
}

.clicked-coord-label {
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 5px;
}

.clicked-coord-label p {
  margin: 10px;
}

.ia-forecast {
  background-color: #cae9c8 !important;
}

.LY {
  background-color: #bcdeff !important;
}

#roottest {
  padding: 10px;
}

.impact-table-popover > table,
tr,
td {
  border: 1px solid lightgray;
  border-collapse: collapse;
}

.ia-assort-carryover {
  background-color: #f7eae9 !important;
}
.impact-customized-card {
  display: flex;
}

.progress {
  display: flex;
  justify-content: left;
  align-items: center;
  width: max-content;
  gap: 20px;
  padding: 5px;
}
.progress--text {
  text-align: left;
  max-width: 200px;
}
.secondary-bar {
  position: absolute;
  left: 0;
  z-index: 0;
}

.auth-error {
  font-size: 2.5em;
  position: fixed;
  top: 25%;
  left: 30%;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1/1;
  width: max-content;
}
.tech-error {
  height: 100%;
  display: flex;
  justify-content: center;
  /* padding: 100px; */
}


.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.actions-button-cell.ag-cell-focus.ag-cell-value {
  overflow: visible !important;
}

.ag-row {
  z-index:0;
}
.ag-row-focus {
  z-index:1;
}


.impact-filter-container {
  padding-bottom: 10px;
}

.ia-assort-line-review {
  background-color: lightgray !important;
}